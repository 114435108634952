import React from "react"
import styled from "styled-components"

import DarkModeToggle from "@/components/DarkModeToggle"
import I18nToggle from "@/components/I18nToggle"

import useTranslation from "@/hooks/useTranslation"
import { Link } from "@/i18n"

const NavHeader = () => {
  const t = useTranslation()
  return (
    <Header>
      <div className="header_left">
        <Link to="/">
          <div>{t('home.header')}</div>
        </Link>
      </div>
      <div className="header_right">
        <I18nToggle />
        <DarkModeToggle />
      </div>
    </Header>
  )
}


export default NavHeader

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 32px;
  @media (max-width: 450px) {
    padding: 15px 16px;
    .header_left{
      max-width: 170px;
    }
  }
  .header_left{
    padding: 10px 0;
    a {
      color: var(--acnh-colors-text-primary);
    }
  }
  .header_right{
    display: flex;
  }
`