import React from "react"
import styled from "styled-components"

import useTranslation from "@/hooks/useTranslation"

const NavFooter = () => {
  const t = useTranslation()
  return (
    <Footer>
      <div className="footer_copyRight">
        © {new Date().getFullYear()}, {t("home.copyRight")}
      </div>
    </Footer>
  )
}

export default NavFooter

const Footer = styled.footer`
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  padding: 32px;
  @media (max-width: 450px) {
    padding: 40px 16px;
    font-size: 14px;
  }
  .footer_copyRight{
    margin: 15px 0;
  }
`
